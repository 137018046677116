.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-fixed;
  background-color: $body-color;
  .nav {
    max-width: 968px;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__logo,
    &__toggle {
      color: $title-color;
      font-weight: $font-medium;
    }
    &__logo {
      &:hover {
        color: $first-color;
      }
    }
    &__toggle {
      font-size: 1.1rem;
      cursor: pointer;
      &:hover {
        color: $first-color;
      }
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: $small-font-size;
      color: $title-color;
      font-weight: $font-medium;
      &:hover {
        color: $first-color;
      }
    }
    &__icon {
      font-size: 1.2rem;
    }
    &__close {
      position: absolute;
      right: 1.3rem;
      bottom: 0.5rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: $first-color;
      &:hover {
        color: $first-color-alt;
      }
    }
    &__menu {
      @include mobile {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: $body-color;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 1s;
        &.show {
          bottom: 0;
          transition: 0.3s;
        }
      }
    }
  }
}
