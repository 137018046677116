.comingsoon {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);
  &__container {
    text-align: center;
  }
  &__image {
    max-width: 250px;
  }
  &__text {
    font-size: 2rem;
    font-weight: 500;
  }
}
