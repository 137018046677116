/*========== Colors ==========*/
/* Change favorite color */
$hue-color: 250;

/* HSL color mode */
$first-color: hsl($hue-color, 69%, 61%);
$first-color-second: hsl($hue-color, 69%, 61%);
$first-color-alt: hsl($hue-color, 57%, 53%);
$first-color-lighter: hsl($hue-color, 92%, 85%);
$title-color: hsl($hue-color, 8%, 15%);
$text-color: hsl($hue-color, 8%, 45%);
$text-color-light: hsl($hue-color, 8%, 65%);
$input-color: hsl($hue-color, 70%, 96%);
$body-color: hsl($hue-color, 60%, 99%);
$container-color: white;

/*========== Font Size ==========*/

$big-font-size: 2rem;
$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1.125rem;
$normal-font-size: 0.938rem;
$small-font-size: 0.813rem;
$smaller-font-size: 0.75rem;

/*========== Font weight ==========*/
$font-medium: 500;
$font-semi-bold: 600;

/*========== Margenes Bottom ==========*/
/* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
$mb-0-25: 0.25rem;
$mb-0-5: 0.5rem;
$mb-0-75: 0.75rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;
$mb-3: 3rem;

/*========== z index ==========*/
$z-tooltip: 10;
$z-fixed: 100;
$z-modal: 1000;

$header-height: 3rem;

@media screen and (min-width: 968px) {
  $big-font-size: 3rem;
  $h1-font-size: 2.25rem;
  $h2-font-size: 1.5rem;
  $h3-font-size: 1.25rem;
  $normal-font-size: 1rem;
  $small-font-size: 0.875rem;
  $smaller-font-size: 0.813rem;
}
