* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0 0 $header-height 0;
  font-family: $body-font;
  font-size: $normal-font-size;
  background-color: $body-color;
  color: $text-color;
}

h1,
h2,
h3,
h4 {
  color: $text-color;
  font-weight: $font-semi-bold;
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  background: none;
  border: none;
  font-family: --body-font;
}

img {
  max-width: 100%;
  height: auto;
}
.section {
  padding: 2rem 0 4rem;
}
